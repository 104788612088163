"use client";
import React, { useEffect, useRef } from "react";
import { motion, useMotionTemplate, useMotionValue } from "framer-motion";

import EcosystemIcon from "../assets/icons/ecosystem.svg";
import { FeatherIcon } from "lucide-react";

export const Feature = ({
  title,
  description,logo
}: {
  title: string;
  description: string;logo:React.ReactNode;
}) => {
  const offsetX = useMotionValue(-100);
  const offsetY = useMotionValue(-100);
  const maskImage = useMotionTemplate`radial-gradient(120px 120px at ${offsetX}px ${offsetY}px, black, transparent)`;

  const border = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const updateMousePosition = (e: MouseEvent) => {
      if (!border.current) return;
      const rect = border.current?.getBoundingClientRect();
      offsetX.set(e.x - rect.x);
      offsetY.set(e.y - rect.y);
    };
    window.addEventListener("mousemove", updateMousePosition);
    return () => {
      window.removeEventListener("mousemove", updateMousePosition);
    };
  }, []);

  return (
    <div className='relative rounded-xl border px-5 py-10 text-center dark:border-white/30 sm:flex-1'>
      <motion.div
        className='absolute inset-0 rounded-xl border-2 border-purple-400'
        style={{
          maskImage,
          WebkitMaskImage: maskImage,
        }}
        ref={border}
      ></motion.div>
      <div className='inline-flex  size-16  items-center justify-center rounded-lg bg-slate-300/50 text-blue-600 dark:bg-slate-50'>
        {logo}
      </div>
      <h3 className='mt-6 font-bold'>{title}</h3>
      <p className='mt-2 dark:text-white/70'>{description}</p>
    </div>
  );
};
