/* eslint-disable react/no-unescaped-entities */
"use client";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import helixImage from "../assets/images/helix2.png";
import emojiStarImage from "../assets/images/emojistar.png";
import { useScroll, useTransform, motion, useInView } from "framer-motion";
import { useRef, useState, useTransition } from "react";

import { z } from "zod";
import { EmailSchema } from "@/schemas/newsLetter";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { Input } from "@/components/ui/input";
import { registerEmail } from "@/actions/news-letter/register-email.action";

export const CallToAction = () => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  const containerRef = useRef<HTMLDivElement>(null);
  const [isPending, startTransition] = useTransition();
  const [error, setError] = useState<string | undefined>();
  const [success, setSuccess] = useState<string | undefined>();

  const form = useForm<z.infer<typeof EmailSchema>>({
    resolver: zodResolver(EmailSchema),
    defaultValues: {
      email: "",
      antiSpam: "",
    },
  });

  const onSubmit = (data: z.infer<typeof EmailSchema>) => {
    setError(undefined);
    setSuccess(undefined);
    startTransition(async () => {
      try {
        const result = await registerEmail(data);

        if (result?.data?.success) {
          setSuccess(result?.data.success);
        } else {
          setError(result?.data?.failure);
        }
        if (result?.serverError) setError(result?.serverError);
      } catch (error) {
        setError("Une erreur est survenue");
      }
    });
  };

  const { scrollYProgress } = useScroll({
    target: containerRef,
    offset: ["start start", "end end"],
  });

  const translateY = useTransform(scrollYProgress, [0, 1], [-80, 80]);

  return (
    <div
      className='overflow-hidden bg-slate-50 py-[72px] text-center dark:bg-black dark:text-white sm:p-24'
      ref={containerRef}
    >
      <motion.div
        ref={ref}
        initial={{ y: 100, opacity: 0 }}
        animate={isInView ? { y: 0, opacity: 1 } : { y: 100, opacity: 0 }}
        transition={{ duration: 0.5 }}
        className='container relative max-w-xl '
      >
        {/* <motion.div style={{ translateY }} >
          <Image src={helixImage} alt="helix" className="absolute left-[calc(100%+36px)] top-6" />
        </motion.div>
        <motion.div style={{ translateY }} >
          <Image src={emojiStarImage} alt="emoji star" className="absolute right-[calc(100%+24px)] top-[90px]" />
        </motion.div> */}
        <h2 className='text-5xl font-bold tracking-tighter sm:text-6xl'>
          Restez informé du lancement !
        </h2>
        <p className='mt-5 text-xl  dark:text-white/70'>
          Ne manquez pas le lancement ! Inscrivez votre email pour être informé
          dès que notre application sera disponible et commencer à améliorer
          votre anglais.
        </p>
        {success && (
          <div className='mx-auto mt-10 max-w-md rounded-md bg-emerald-400/50 p-4 '>
            Merci de nous rejoindre!
            <br /> Votre email à bien été enregistré.
            <br /> On vous tiens au courant des nouveautés.
          </div>
        )}
        {error && (
          <div className='mx-auto mt-10 max-w-md rounded-md bg-destructive/80 p-4 '>
            {error}
          </div>
        )}
        <Form {...form}>
          <form
            className='mx-auto mt-10 flex max-w-md flex-col justify-center gap-2.5 sm:flex-row'
            onSubmit={form.handleSubmit(onSubmit)}
          >
            <FormField
              control={form.control}
              name='email'
              render={({ field }) => (
                <FormItem>
                  <FormLabel hidden>Email</FormLabel>
                  <FormControl>
                    <Input
                      {...field}
                      placeholder='john@doe.com'
                      type='email'
                      disabled={isPending}
                      className=' size-full flex-1  rounded-lg bg-black/10 px-5 py-3 font-medium outline-none placeholder:text-black dark:bg-white/20 dark:placeholder:text-[#9CA3AF]'

                      //disabled={isPending}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name='antiSpam'
              render={({ field }) => (
                <FormItem>
                  <FormLabel hidden>anti-spam</FormLabel>
                  <FormControl>
                    <Input
                      {...field}
                      placeholder='john@doe.com'
                      type='email'
                      className=' hidden'

                      //disabled={isPending}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <button
              disabled={isPending}
              className='h-12 rounded-lg  bg-black px-5 text-white dark:bg-white dark:text-black'
            >
              Rester informé
            </button>
          </form>
        </Form>
      </motion.div>
    </div>
  );
};
