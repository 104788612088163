/* eslint-disable react/no-unescaped-entities */
"use client"
import { ChartColumn, GraduationCap, MessageCircleMore } from "lucide-react";
import { Feature } from "./feature";
import { useRef } from "react";
import { useInView, motion } from "framer-motion";

const features = [
  {
    title: "Discutez en anglais à tout moment",
    description:
      "Améliorez votre expression orale et écrite grâce à notre IA disponible 24/7. Adaptez les conversations à votre niveau et recevez des conseils en temps réel.",
    logo: <MessageCircleMore className='size-8 text-blue-600' />,
  },
  {
    title: "Testez et perfectionnez vos compétences",
    description:
      "Relevez des défis adaptés à votre niveau avec nos modules de tests variés. Progressez à votre rythme en renforçant vos acquis.",
    logo: <GraduationCap className='size-8 text-blue-600' />,
  },
  {
    title: "Suivez vos progrès en un coup d'œil",
    description:
      "Accédez à une page dédiée pour visualiser vos performances. Identifiez vos points forts et axes d'amélioration pour un apprentissage efficace.",
    logo: <ChartColumn className='size-8 text-blue-600' />,
  },
];

export const Features = () => {
  const ref = useRef(null);
  const ref2 = useRef(null);
  const isInView = useInView(ref, { once: true });
  const isInView2 = useInView(ref2, { once: true });

  return (
    <motion.div
      ref={ref}
      initial={{ y: 100, opacity: 0 }}
      animate={isInView ? { y: 0, opacity: 1 } : { y: 100, opacity: 0 }}
      transition={{ duration: 0.5 }}
      className='bg-slate-50 py-[72px] dark:bg-black dark:text-white sm:py-24'
    >
      <div className='container'>
        <h2 className='text-center text-5xl font-bold tracking-tighter sm:text-6xl'>
          Boostez votre apprentissage
        </h2>
        <div className='mx-auto max-w-xl'>
          <p className='mt-5 text-center text-xl dark:text-white/70'>
            Apprenez l'anglais comme jamais auparavant grâce à notre IA
            interactive. <br />
            Pratiquez à l'oral ou à l'écrit, testez vos compétences avec des
            modules variés, et recevez des conseils personnalisés pour
            progresser à votre rythme.
          </p>
        </div>
        <motion.div
          ref={ref2}
          initial={{ y: 100, opacity: 0 }}
          animate={isInView2 ? { y: 0, opacity: 1 } : { y: 100, opacity: 0 }}
          transition={{ duration: 0.5 }}
          className='mt-16 flex flex-col gap-4 sm:flex-row'
        >
          {features.map((feature, index) => (
            <Feature
              key={index}
              title={feature.title}
              description={feature.description}
              logo={feature.logo}
            />
          ))}
        </motion.div>
      </div>
    </motion.div>
  );
};
